const h3 = `
    font-size: 2.25rem;
    line-height: 56px;
`;

const h4 = `
    font-size: 2rem;
    line-height: 40px;
`;

const h5 = `
    font-size: 1.5rem;
    line-height: 32px;
`;

const h6 = `
    font-size: 1.25rem;
    line-height: 32px;
`;

const sub1 = `
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.4px;
`;

const sub2 = `
    font-size: 0.85rem;
    line-height: 20px;
    letter-spacing: 0.2px;
`;

const body1 = `
    font-size: 0.75rem;
    line-height: 20px;
`;

const body2 = `
    font-size: 0.5rem;
    line-height: 20px;
    letter-spacing: 0.2px;
`;

const types = {
  h3,
  h4,
  h5,
  h6,
  sub1,
  sub2,
  body1,
  body2
};

const weights = {
  bold: `font-family: Kayak-Bold`,
  regular: `font-family: Kayak-Regular`,
  light: `font-family: Kayak-Light`
};

export const typos = {
  h3: {
    bold: types.h3 + weights.bold,
    regular: types.h3 + weights.regular,
    light: types.h3 + weights.light
  },
  h4: {
    bold: types.h4 + weights.bold,
    regular: types.h4 + weights.regular,
    light: types.h4 + weights.light
  },
  h5: {
    bold: types.h5 + weights.bold,
    regular: types.h5 + weights.regular,
    light: types.h5 + weights.light
  },
  h6: {
    bold: types.h6 + weights.bold,
    regular: types.h6 + weights.regular,
    light: types.h6 + weights.light
  },
  sub1: {
    regular: types.sub1 + weights.regular,
    light: types.sub1 + weights.light,
    bold: types.sub1 + weights.bold
  },
  sub2: {
    regular: types.sub2 + weights.regular,
    light: types.sub2 + weights.light,
    bold: types.sub2 + weights.bold
  },
  body1: {
    regular: types.body1 + weights.regular,
    light: types.body1 + weights.light,
    bold: types.body1 + weights.bold
  },
  body2: {
    regular: types.body2 + weights.regular,
    light: types.body2 + weights.light,
    bold: types.body2 + weights.bold
  }
};

export default typos;
