import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ListaAnalisis } from 'images';
import useAxios from 'hooks/useAxios';
import { useUser } from 'hooks/useUser';
import { loginMedicos } from 'utils/httpQueries';
import TitleSection from 'components/titleSection';
import FormElement from 'components/formElement';
import { Separator } from 'styles/elements';
import {
  BackDrop,
  Container,
  Content,
  ErrorMessage,
  Input,
  Submit
} from './styles';

type Inputs = {
  user: string;
  password: string;
};

export default () => {
  const { login, userInfo } = useUser();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();
  const { loading, setHttpParams } = useAxios({
    onSuccess: (response: any) => {
      const { medico, token } = response.data;
      login({
        id: medico.id,
        name: medico.nombre,
        lastname: medico.apellido,
        role: medico.type,
        token
      });
    },
    onError: (error: any) => {
      console.log(error);
    }
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    setHttpParams(
      loginMedicos({
        username: data.user,
        password: data.password
      })
    );
  };

  useEffect(() => {
    if (!!userInfo) {
      history.push('/medicos');
    }
  }, [userInfo]);

  return (
    <>
      <TitleSection text="Iniciar Sesión" image={ListaAnalisis} />
      <BackDrop>
        <Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormElement label="Usuario" full={true}>
              <Container>
                <Input
                  {...register('user', {
                    required: {
                      value: true,
                      message: 'Porfavor ingresar tu usuario '
                    }
                  })}
                />
              </Container>
            </FormElement>

            {errors?.user && (
              <>
                <Separator vertical={0.5} />
                <ErrorMessage>{errors.user.message}</ErrorMessage>
              </>
            )}
            <Separator vertical={1.7} />
            <FormElement label="Contraseña" full={true}>
              <Container>
                <Input
                  type="password"
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Porfavor ingresar tu contraseña '
                    }
                  })}
                />
              </Container>
            </FormElement>
            {errors?.password && (
              <>
                <Separator vertical={0.5} />
                <ErrorMessage>{errors.password.message}</ErrorMessage>
              </>
            )}
            <Separator vertical={3} />
            <Submit
              loading={loading}
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              Entrar
            </Submit>
          </form>
        </Content>
      </BackDrop>
    </>
  );
};
