import React, { useState } from 'react';
import axios from 'axios';
import TitleSection from 'components/titleSection';
import ScreenLoader from 'components/screenLoader';
import { Resultado } from 'images';
import { Subtitle } from 'styles/elements';
import EmptyCard from 'components/emptyCard';
import {
  WrapperLocations,
  Input,
  Container,
  Button,
  Content,
  Card,
  BodyCard,
  IconCard,
  Icon,
  RegularText,
  BoldText,
  TextStack,
  WrapperButtons,
  HyperText,
  Stack,
  OrdenCard,
  OrdenBody,
  MoreDetail,
  Table,
  BodyTable,
  HeadTable,
  BackgroundTable
} from './styles';
import FormElement from 'components/formElement';

/* Components */

const goOrder = (gestion: any, codigo: any) => {
  const urlOrder = `http://200.119.213.75/catedral/externo/consultasdelmedicosolicitante/resultados_original_pdf.php?id_tipoanalisis=3&codigo=${codigo}&id_gestion=${gestion}`;
  window.open(urlOrder, '_blank');
};

const InfoCard = ({ paciente }: { paciente: any }) => (
  <Card>
    <IconCard>
      <Icon name="user" color="white" />
    </IconCard>
    <BodyCard>
      <Stack>
        <TextStack>
          <RegularText>Nombre:</RegularText>
          <BoldText>{paciente.nombre}</BoldText>
        </TextStack>
        <TextStack>
          <RegularText>Apellido:</RegularText>
          <BoldText>{paciente.apellidos}</BoldText>
        </TextStack>
        <TextStack>
          <RegularText>Genero:</RegularText>
          <BoldText>{paciente.genero}</BoldText>
        </TextStack>
      </Stack>
      <div>
        <TextStack>
          <RegularText>Cedula de Identidad:</RegularText>
          <BoldText>{paciente.documento || 'N'}</BoldText>
        </TextStack>
        <TextStack>
          <RegularText>Fecha de Nacimiento:</RegularText>
          <BoldText>{paciente.fechaNacimiento}</BoldText>
        </TextStack>
      </div>
    </BodyCard>
  </Card>
);

const OrderComponent = ({ orden }: { orden: any }) => {
  const [showDetail, setShowDetail] = useState<Boolean>(false);
  return (
    <>
      <OrdenCard>
        <OrdenBody>
          <Stack>
            <TextStack>
              <BoldText>Orden: {orden.codigo}</BoldText>
            </TextStack>
            <TextStack>
              <BoldText>Fecha: {orden.fechaToma}</BoldText>
            </TextStack>
            <TextStack>
              <BoldText>Medico Solicitante: {orden.medicoSolicitante}</BoldText>
            </TextStack>
          </Stack>
          <Stack>
            <TextStack>
              <BoldText>
                Estado: {orden.fechaEntrega ? 'Entregado' : 'No Entregado'}
              </BoldText>
            </TextStack>
            <TextStack>
              <BoldText>Fecha de Entrega: {orden.fechaEntrega || 'N'}</BoldText>
            </TextStack>
          </Stack>
          <WrapperButtons>
            <MoreDetail onClick={() => setShowDetail(!showDetail)}>
              Ver detalle de la orden
            </MoreDetail>
            <HyperText
              onClick={() => goOrder(orden.gestion, orden.codigo)}
              rel="noreferrer noopener"
            >
              Descargar orden
            </HyperText>
          </WrapperButtons>
        </OrdenBody>
      </OrdenCard>
      {showDetail && (
        <BackgroundTable>
          <Table>
            <thead>
              <tr>
                <HeadTable porcentage={25} scope="col">
                  Estudio
                </HeadTable>
                <HeadTable porcentage={20} scope="col">
                  Muestra
                </HeadTable>
                <HeadTable porcentage={30} scope="col">
                  Medico
                </HeadTable>
                <HeadTable porcentage={20} scope="col">
                  Entregado
                </HeadTable>
              </tr>
            </thead>
            <tbody>
              {orden.analisis.map((item: any, index: any) => (
                <tr key={index}>
                  <BodyTable>{item.nombreAnalisis}</BodyTable>
                  <BodyTable>{item.muestra}</BodyTable>
                  <BodyTable>{`${item.medico.nombre} ${item.medico.apellidos}`}</BodyTable>
                  <BodyTable>
                    {item.medico.nombre != 'not provided'
                      ? 'Listo'
                      : 'No listo'}
                  </BodyTable>
                </tr>
              ))}
            </tbody>
          </Table>
        </BackgroundTable>
      )}
    </>
  );
};

export default () => {
  const [nombrePaciente, setNombrePaciente] = useState<string>('');
  const [dob, setDob] = useState<string>('');
  const [loading, setLoading] = useState<Boolean>(false);
  const [data, setData] = useState<any>();

  const onSubmit = () => {
    setData(null);
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}resultados/historico?apellido=${nombrePaciente}&dob=${dob}`
      )
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        setData([]);
        console.log(e);
      });
  };

  return (
    <>
      <TitleSection text="Resultados" image={Resultado} />
      <WrapperLocations>
        <>
          <Subtitle>Consulta tus Resultados</Subtitle>
          <Content>
            <FormElement label="Apellido Paterno">
              <Container>
                <Input
                  placeholder="Ingrese el apellido"
                  value={nombrePaciente}
                  onChange={e => setNombrePaciente(e.target.value.trim())}
                />
              </Container>
            </FormElement>

            <FormElement label="Fecha de Nacimiento">
              <Container>
                <Input
                  type="date"
                  value={dob}
                  onChange={e => setDob(e.target.value)}
                />
              </Container>
            </FormElement>
          </Content>
          <Button
            onClick={() => onSubmit()}
            disabled={
              !(
                nombrePaciente.length > 3 &&
                dob.length > 9 &&
                Number(dob.split('-')[0]) > 1800 &&
                dob.split('-')[0].length < 5
              )
            }
          >
            Buscar
          </Button>
        </>
        {!loading && data && (
          <>
            {data.paciente ? (
              <>
                <InfoCard paciente={data.paciente} />
                {data.ordenes.map((orden: any, index: number) => (
                  <OrderComponent orden={orden} key={index} />
                ))}
              </>
            ) : (
              <EmptyCard
                title="No hay resultado"
                message="Lo sentimos no existe el paciente que estas buscando"
              />
            )}
          </>
        )}
      </WrapperLocations>
      {loading && <ScreenLoader />}
    </>
  );
};
