import styled from 'styled-components';
import { Icon as I } from 'ts-react-feather-icons';

const Slide = require('react-reveal/Zoom');

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 35vw;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  width: 100vw;
  height: 35vw;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  object-fit: cover;
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: '7.5vw',
  color: props.theme.colors.white
}))`
  opacity: 0.75;
`;

export const Controller = styled.div<{ left: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  z-index: 100;
  ${props => (props.left ? 'left: 2vw' : 'right: 2vw')}
`;

export const Dot = styled.div<{ activate: boolean }>`
  width: 1.5vw;
  height 1.5vw;
  border-radius: 0.75vw;
  background-color: ${props =>
    props.activate ? props.theme.colors.catedralRed : props.theme.colors.white};
  opacity: ${props => (props.activate ? '0.75' : '0.65')};
  margin: 0 0.5vw

`;

export const DotsContainer = styled.div`
  position: absolute;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1.5vw;
  z-index: 100;
`;
