import styled from 'styled-components';
import { Icon as I } from 'ts-react-feather-icons';

export const WrapperLocations = styled.div`
  margin: 1.5em 6vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${props => props.theme.breakpoints.laptopL} {
    margin: 2.25em 6vw;
  }
`;

export const Subtitle = styled.h3`
  font-family: Kayak-bold;
`;

export const Selected = styled.div`
  width: 100%;
  margin-top: 3em;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 45%;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 35%;
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 0.75em;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 1.5em;
`;

export const Content = styled.div`
  font-family: Kayak-Regular;
  margin-right: auto;
`;

export const Duration = styled.div`
  font-size: 0.85em;
  margin-top: 0.25em;
`;

export const Button = styled.button`
  display: flex;
  background-color: black;
  padding: 0.75em;
  aspect-ratio: 1;
  justify-content: center;
  border: none;
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: '1em',
  color: props.theme.colors[props.color || 'white']
}))`
  align-self: center;
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.25em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 2.5em;
  }
`;

export const Submit = styled.button`
  width: 100%;
  font-family: Kayak-bold;
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.shawdow
      : props.theme.colors.catedralRed};
  color: ${props => props.theme.colors.white};
  border: 0;
  box-shadow: 2px 2px 5px #999;
  font-size: 1em;
  padding: 0.75em;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 45%;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.25em;
    padding: 0.78em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 35%;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5em;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 45%;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 45%;
  }
`;

export const HeadTable = styled.th<{ porcentage: number }>`
  border: 1px solid black;
  padding: 1em;
  text-align: left;
  font-family: Kayak-bold;
  width: ${props => props.porcentage}%;
`;

export const BodyTable = styled.td`
  border: 1px solid black;
  font-family: Kayak-regular;
  padding: 1em;
`;
