import React from 'react';
import { Content, Body, Text, ImageWrapper, Image } from './styles';

interface Props {
  image: string;
  text: string;
  position: 'left' | 'right';
}
const FlatCard: React.FC<Props> = ({ image, text, position }) => {
  return (
    <Content>
      <ImageWrapper position={position == 'left' ? 1 : 2}>
        <Image src={image} />
      </ImageWrapper>
      <Body position={position == 'left' ? 2 : 1}>
        <Text>{text}</Text>
      </Body>
    </Content>
  );
};

export default FlatCard;
