import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const RedirectButton = styled(NavLink)<{ full: boolean }>`
  text-decoration: none;
  background-color: ${props => props.theme.colors.catedralRed};
  border-color: ${props => props.theme.colors.catedralRed};
  border-radius: ${props => props.theme.borderRadius.xs};
  border: 1px solid ${props => props.theme.colors.catedralRed};
  box-shadow: 0.05em 0.1em 0.2em #332f2d;
  width: ${props => (props.full ? '100%' : 'auto')};
`;

export const ExternalRedirect = styled.button<{ full: boolean }>`
  text-decoration: none;
  background-color: ${props => props.theme.colors.catedralRed};
  border-color: ${props => props.theme.colors.catedralRed};
  border-radius: ${props => props.theme.borderRadius.xs};
  border: 1px solid ${props => props.theme.colors.catedralRed};
  box-shadow: 0.05em 0.1em 0.2em #332f2d;
  width: ${props => (props.full ? '100%' : 'auto')};
`;
export const TextButton = styled.p`
  ${props => props.theme.typo.body2.bold};
  font-size: 0.75em;
  line-height: 1.25em;
  margin: 0;
  text-align: center;
  padding: 0.5em;
  color: ${props => props.theme.colors.white};
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 0.75em;
    line-height: 1.25em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 0.9em;
    line-height: 1em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 1.45em;
  }
`;
