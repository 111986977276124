import styled from 'styled-components';
import { Icon as I } from 'ts-react-feather-icons';
import { NavLink } from 'react-router-dom';

export const Content = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.catedralRed};
  padding: 1rem 1.5em 0.5rem;
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 1rem 4em;
  }
`;

export const Brand = styled.img`
  width: 16vw;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 10vw;
  }
`;

export const BrandContainer = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: 30,
  color: props.theme.colors.white
}))``;
