import React, { useEffect } from 'react';
import { useUser } from 'hooks/useUser';
import { Switch, Link, useHistory } from 'react-router-dom';
import PrivateRoute, { Role } from 'components/privateRoute';
import Login from './login';
import Ordenes from './ordenes';
import DetallePaciente from './DetallePaciente';
import DetalleOrden from './DetalleOrden';

/* Components */

const has = () => {
  const { userInfo } = useUser();

  return (
    <div>
      <embed
        src="http://200.119.213.75:81/catedral/externo/consultasdelmedicosolicitante/resultados_original_pdf.php?id_tipoanalisis=3&codigo=C-120&id_gestion=64"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
  );
};

export default () => {
  return (
    <>
      <Switch>
        <PrivateRoute
          path="/medicos"
          element={Ordenes}
          redirectTo="/medicos/login"
          isProtected={true}
          exact={true}
        />
        <PrivateRoute
          path="/medicos/paciente/:id"
          element={DetallePaciente}
          redirectTo="/medicos/login"
          isProtected={true}
        />
        <PrivateRoute
          path="/medicos/orden/:id"
          element={DetalleOrden}
          redirectTo="/medicos/login"
          isProtected={true}
        />
        <PrivateRoute
          path="/medicos/login"
          element={Login}
          isProtected={false}
        />
      </Switch>
    </>
  );
};
