import React, { useState } from 'react';
import { containerStyle, options } from './styles';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader
} from '@react-google-maps/api';

interface Props {
  mapName: string;
  lng: number;
  lat: number;
}

const mapStyles = {
  width: '100%',
  height: '100%'
};

const Map: React.FC<Props> = ({ lng, lat, mapName }) => {
  const [clickMarker, setClickMarker] = useState<boolean>(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBHjGVIFlvf_EXYLAe1EZMb4is7DgIgbH8'
  });

  const mapRef = React.useRef<google.maps.Map<Element> | null>(null);
  const onLoad = (map: google.maps.Map<Element>): void => {
    mapRef.current = map;
  };
  const unMount = (): void => {
    mapRef.current = null;
  };

  if (!isLoaded) return <div>maploading</div>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={18}
      center={{ lat: lat, lng: lng }}
      options={options}
      onLoad={onLoad}
      onUnmount={unMount}
    >
      <Marker
        position={{ lat: lat, lng: lng }}
        onClick={() => setClickMarker(true)}
      />
      {clickMarker && (
        <InfoWindow
          position={{ lat: lat, lng: lng }}
          onCloseClick={() => setClickMarker(false)}
        >
          <div>
            <h3>{mapName}</h3>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default Map;
