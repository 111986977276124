import React from 'react';
import logoCompany from 'images/logo_company.png';
import {
  Footer,
  Container,
  Wrapper,
  IconContainer,
  Icon,
  QualityPolitics,
  TextBody1,
  ExtraInfo,
  TextBody2
} from './styles';

export default () => {
  return (
    <Footer>
      <Container>
        <QualityPolitics>
          <TextBody1 bold={true}>Nuestra Politica de Calidad</TextBody1>
          <TextBody1 bold={false}>
            Laboratorio Catedral es una empresa de servicios medicos
            laboratoriales en anatomia patologica citologia y analisis clinicos,
            que brinda calidad en el diagnostico, mediante la aplicacion
            actualizada de formacion y tecnologia, personal medico y bioquimico
            competente y proveedores confiables que garantizan una excelente
            atencion y satisfaccion de sus clientes
            <br /> <br />
            Asi el Laboratorio y sus redes ser comprometen a ejecutar el
            cumplimiento de la mejora y continua de su Sistema de Gestion de
            calidad y la capacitacion permanente de sus recursos humanos, dentro
            de los principios eticos dirigidos a preservar la vida
          </TextBody1>
        </QualityPolitics>
        <ExtraInfo>
          <Wrapper>
            <TextBody2 align="center">Horarios de atencion:</TextBody2>
            <TextBody2 align="justify">
              Lun - Vie 7:00 – 19:00 hrs <br />
              Sab 07:00 – 12:30hrs
            </TextBody2>
          </Wrapper>
          <Wrapper>
            <TextBody2 align="justify">
              <IconContainer>
                <Icon name="instagram" />
                /Laboratorio Catedral <br /> <br />
              </IconContainer>
              <IconContainer>
                <Icon name="at-sign" />
                contacto@laboratoriocatedral.com <br />
                <br />
              </IconContainer>
              <IconContainer>
                <Icon name="phone" />
                (333)-3305
              </IconContainer>
            </TextBody2>
          </Wrapper>
        </ExtraInfo>
      </Container>
      <Wrapper>
        <TextBody2 align="center">
          © 2021 Copyright Laboratorio Catedral
        </TextBody2>
      </Wrapper>
    </Footer>
  );
};
