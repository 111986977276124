import styled from 'styled-components';

export const Content = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 5%;
  right: 2%;
  display: flex;
  justify-content: center;
  padding: 0.75em;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #25d366;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;
