import styled from 'styled-components';
import { Icon as I } from 'ts-react-feather-icons';

export const Content = styled.div`
  position: relative;
  width: 100%;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 45%;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 35%;
  }
`;

export const Prueba = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
`;

export const Suggestion = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  box-shadow: 2px 2px 5px #999;
  padding: 0.75em 0.5em;
  :hover {
    background-color: #ededed;
  }
`;

export const TagName = styled.span`
  margin-right: auto;
  font-family: Kayak-Regular;
`;

export const Input = styled.input`
  width: 100%;
  padding-left: 0.5em;
  font-family: Kayak-Regular;
  font-size: 1em;
  &::-moz-focus-inner {
    border: 0;
  }
  &:focus {
    outline: 0;
  }
  border: none;
`;

export const Container = styled.div`
  box-shadow: 2px 2px 5px #999;
  border: 1px solid black;
  display: flex;
  width: 100%;
`;

export const Button = styled.div`
  display: flex;
  background-color: black;
  padding: 0.75em;
  aspect-ratio: 1;
  justify-content: center;
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: '1.25em',
  color: props.theme.colors[props.color || 'white']
}))`
  align-self: center;
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.25em;
  }
`;
