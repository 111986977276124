import React, { useEffect, useState } from 'react';
import { Container, PageContainer, PageItem, Icon } from './styles';

interface Props {
  postPerPage: number;
  totalItems: number;
  currentPage: number;
  paginate: Function;
  pageRangeDisplayed?: number;
}

const Paginator: React.FC<Props> = ({
  postPerPage,
  totalItems,
  paginate,
  currentPage,
  pageRangeDisplayed = 4
}) => {
  const [firstRange, setFirstRange] = useState<number>(currentPage);
  const [limitRange, setLimitRange] = useState<number>(pageRangeDisplayed);

  const lastPage = Math.ceil(totalItems / postPerPage);

  const pageNumber = [];
  for (let i = firstRange; i <= limitRange; i++) {
    pageNumber.push(i);
  }

  const nextPage = () => currentPage != lastPage && paginate(currentPage + 1);
  const previousPage = () => currentPage != 1 && paginate(currentPage - 1);

  useEffect(() => {
    if (currentPage > limitRange && currentPage < lastPage) {
      setFirstRange(currentPage);
      setLimitRange(limitRange + pageRangeDisplayed);
    } else if (currentPage < firstRange) {
      setFirstRange(firstRange - pageRangeDisplayed);
      setLimitRange(currentPage);
    }
  }, [currentPage]);

  return (
    <Container>
      <PageContainer>
        <PageItem borderRLeft={true} onClick={previousPage}>
          <Icon name="chevron-left" color={'darkBlue'} />
        </PageItem>
        {pageNumber.map(page => (
          <PageItem
            key={page}
            selected={page == currentPage}
            onClick={() => paginate(page)}
          >
            {page}
          </PageItem>
        ))}

        <PageItem borderRRight onClick={nextPage}>
          <Icon name="chevron-right" color={'darkBlue'} />
        </PageItem>
      </PageContainer>
    </Container>
  );
};

export default Paginator;
