import { Method } from 'axios';

export interface httpParams {
  method: Method;
  url: string;
  body?: Object;
  config?: any;
}

function authenticatedHeader() {
  const token = localStorage.getItem('token');
  return { headers: { Authorization: `Bearer ${token}` } };
}

export const getAllAnalisis = (): httpParams => ({
  url: 'analisis/listado',
  method: 'get'
});

export const getMedicoProfile = (): httpParams => ({
  url: 'medicos/myinfo',
  method: 'get',
  config: authenticatedHeader()
});

export const loginMedicos = ({
  username,
  password
}: {
  username: string;
  password: string;
}): httpParams => ({
  url: 'medicos/login',
  method: 'post',
  body: {
    username,
    password
  }
});

export const getOrdenesMedicos = ({ limit = 10, page = 1 }): httpParams => ({
  url: `medicos/ordenes?page=${page}&limit=${limit}`,
  method: 'get',
  config: authenticatedHeader()
});

export const getAllMyPacientes = (): httpParams => ({
  url: `medicos/pacientes`,
  method: 'get',
  config: authenticatedHeader()
});

export const getPacienteDetail = ({
  idPaciente
}: {
  idPaciente: any;
}): httpParams => ({
  url: `medicos/paciente/${idPaciente}`,
  method: 'get',
  config: authenticatedHeader()
});

export const getOrderDetails = ({ idOrden }: { idOrden: any }): httpParams => ({
  url: `medicos/orden/${idOrden}`,
  method: 'get',
  config: authenticatedHeader()
});
