import Autocomplete from 'components/autocomplete';
import IconicButton from 'components/iconicButton';
import Paginator from 'components/paginator';
import ScreenLoader from 'components/screenLoader';
import { capitalize } from 'helpers/text';
import useAxios from 'hooks/useAxios';
import { useUser } from 'hooks/useUser';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DangerText, Separator } from 'styles/elements';
import { getAllMyPacientes, getOrdenesMedicos } from 'utils/httpQueries';
import {
  BodyTable,
  Container,
  HeadTable,
  MarginTitle,
  Stack,
  Table,
  TableRow,
  TableTitle,
  TitleHeading
} from '../styles';
/* Components */

const goOrder = (gestion: any, codigo: any) => {
  const urlOrder = `http://200.119.213.75/catedral/externo/consultasdelmedicosolicitante/resultados_original_pdf.php?id_tipoanalisis=3&codigo=${codigo}&id_gestion=${gestion}`;
  window.open(urlOrder, '_blank');
};

const TablePacientes = ({
  data,
  goPaciente
}: {
  data: any[];
  goPaciente: any;
}) => {
  return (
    <Table>
      <HeadTable>
        <tr>
          <TableTitle porcentage={5} scope="col">
            Orden
          </TableTitle>
          <TableTitle porcentage={8} scope="col">
            Fecha
          </TableTitle>
          <TableTitle porcentage={10} scope="col">
            Nombre
          </TableTitle>
          <TableTitle porcentage={10} scope="col">
            Apellido Paterno
          </TableTitle>
          <TableTitle porcentage={10} scope="col">
            Apellido Materno
          </TableTitle>
          <TableTitle porcentage={10} scope="col">
            Estado
          </TableTitle>
          <TableTitle porcentage={6} scope="col">
            Acciones
          </TableTitle>
        </tr>
      </HeadTable>
      <tbody>
        {data.map((item: any, index: number) => (
          <TableRow key={item.id} even={index % 2 == 0}>
            <BodyTable>{item.codigo}</BodyTable>
            <BodyTable>{item.fecha}</BodyTable>
            <BodyTable>{capitalize(item.paciente.nombre)}</BodyTable>
            <BodyTable>{capitalize(item.paciente.apellidoP)}</BodyTable>
            <BodyTable>{capitalize(item.paciente.apellidoM)}</BodyTable>
            <BodyTable>
              {item.cantidadAnalisis === item.cantidadAnalisisListo
                ? 'Listo'
                : `Pendiente (${item.cantidadAnalisisListo}/${item.cantidadAnalisis})`}
            </BodyTable>
            <BodyTable>
              <Stack>
                <IconicButton
                  icon="printer"
                  size={'1.2em'}
                  color={'darkBlue'}
                  onClick={() => goOrder(item.gestion, item.codigo)}
                />
                <IconicButton
                  icon="folder-plus"
                  size={'1.2em'}
                  color={'darkBlue'}
                  onClick={() => goPaciente(item.paciente.id)}
                />
              </Stack>
            </BodyTable>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default () => {
  const history = useHistory();
  const [data, setData] = React.useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit] = useState<number>(10);
  const {
    response,
    loading: load,
    error: errorPacientes
  } = useAxios({
    httpParams: getAllMyPacientes()
  });
  const {
    setHttpParams,
    loading: loadOrdenes,
    error: errorOrdenes
  } = useAxios({
    httpParams: getOrdenesMedicos({
      page: currentPage,
      limit: limit
    }),
    onSuccess: (response: any) => {
      setCurrentPage(response.data.infoPage.currentPage);
      setData(response.data);
    }
  });

  const goPaciente = (id: number) => {
    history.push(`/medicos/paciente/${id}`);
  };

  const paginate = (page: number) =>
    setHttpParams(
      getOrdenesMedicos({
        page: page,
        limit: limit
      })
    );

  const loading = load || loadOrdenes;

  if (loading) {
    return <ScreenLoader />;
  }

  if (errorPacientes || errorOrdenes) {
    return (
      <Container>
        <DangerText>
          Ha ocurrido un error al cargar los datos, por favor intente nuevamente
        </DangerText>
      </Container>
    );
  }

  return (
    <Container>
      <MarginTitle>
        <TitleHeading>Últimas Órdenes</TitleHeading>
      </MarginTitle>

      {!load && (
        <Autocomplete
          placeholder="Ingrese el nombre del paciente"
          items={response?.data.pacientes}
          selectProp={['nombre', 'apellido', 'apellidoMaterno']}
          minLenght={2}
          onChange={(e: any) => goPaciente(e.id)}
        />
      )}
      <Separator vertical={3} />
      {data && <TablePacientes data={data.data} goPaciente={goPaciente} />}
      {data && (
        <Paginator
          postPerPage={limit}
          totalItems={data.infoPage.totalItems}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </Container>
  );
};
