import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logoCompany from 'images/logo_company.png';
import { Content, Brand, IconContainer, BrandContainer, Icon } from './styles';
import Burger from './burger';
import { BlurredBackground } from 'styles/elements';

const Swing = require('react-reveal/Swing');
const Slide = require('react-reveal/Slide');

export default () => {
  const [menu, setMenu] = useState(false);
  const history = useHistory();

  useEffect(() => {
    menu
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [menu]);
  return (
    <>
      <Content>
        <IconContainer onClick={() => setMenu(!menu)}>
          <Swing spy={menu}>
            <Icon name={menu ? 'x' : 'menu'} />
          </Swing>
        </IconContainer>
        <BrandContainer to={'/'}>
          <Brand src={logoCompany} />
        </BrandContainer>
        <IconContainer onClick={() => history.push('/analisis')}>
          <Icon name={'search'} />
        </IconContainer>
      </Content>

      <Slide left when={menu} duration={500}>
        {menu && (
          <div style={{ position: 'relative', zIndex: 500 }}>
            <Burger onClose={() => setMenu(false)} />
          </div>
        )}
      </Slide>
      {menu && <BlurredBackground onClick={() => setMenu(false)} />}
    </>
  );
};
