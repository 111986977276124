import React from 'react';
import { Content, Label } from './styles';

interface Props {
  label: string;
  children: React.ReactNode;
  full?: boolean;
}

const FormElement: React.FC<Props> = ({ label, children, full = false }) => {
  return (
    <Content full={full}>
      <Label>{label}</Label>
      {children}
    </Content>
  );
};

export default FormElement;
