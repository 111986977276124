import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import IconicButton from 'components/iconicButton';
import { capitalize } from 'helpers/text';

import {
  BodyTable,
  Container,
  HeadTable,
  Stack,
  StackItem,
  Subtitle,
  Table,
  TableRow,
  TableTitle,
  TitleHeading
} from '../styles';
import useAxios from 'hooks/useAxios';
import { getOrderDetails, getPacienteDetail } from 'utils/httpQueries';
import ScreenLoader from 'components/screenLoader';
import { DangerText } from 'styles/elements';

/* Components */

interface Props {
  entity: any;
  fieldsR: string[];
  fieldsL: string[];
}

interface Orden {
  analisis: {
    id: number;
    codigo: string;
    codigoAnalisis: string;
    gestion: number;
    nombre: string;
    listo: boolean;
  }[];
}
const getAnalisisPDF = (gestion: any, codigo: any, codigoAnalisis: any) => {
  const url = `http://200.119.213.75/catedral/interno/consultasdelrecepcionista/resultados_original_web.php?codigo=${codigo}&id_gestion=${gestion}&codigos=${codigoAnalisis}`;
  window.open(url, '_blank');
};

const TablaAnalisis = ({ data }: { data: any[] }) => {
  const history = useHistory();

  // redirect to paciente id
  const goPaciente = (id: number) => {
    history.push(`/medicos/paciente/${id}`);
  };

  return (
    <Table>
      <HeadTable>
        <tr>
          <TableTitle porcentage={3} scope="col">
            Nombre de Analisis
          </TableTitle>
          <TableTitle porcentage={8} scope="col">
            Fecha
          </TableTitle>
          <TableTitle porcentage={4} scope="col">
            Estado
          </TableTitle>
          <TableTitle porcentage={4} scope="col">
            Ver PDF
          </TableTitle>
        </tr>
      </HeadTable>
      <tbody>
        {data.map((item: any, index: number) => (
          <TableRow key={item.id} even={index % 2 == 0}>
            <BodyTable>{item.nombre}</BodyTable>
            <BodyTable>{item.fecha}</BodyTable>
            <BodyTable>{item.listo ? 'Listo' : 'Pendiente'}</BodyTable>
            <BodyTable>
              <Stack>
                <IconicButton
                  icon="printer"
                  size={'1.2em'}
                  color={'darkBlue'}
                  onClick={() =>
                    getAnalisisPDF(
                      item.gestion,
                      item.codigo,
                      item.idAnalisisGeneral
                    )
                  }
                />
              </Stack>
            </BodyTable>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

const DetalleOrden: React.FC<Props> = ({ entity, fieldsL, fieldsR }) => {
  const { id } = useParams<{ id: any }>();
  const [data, setData] = React.useState<Orden | null>(null);

  const { loading, error } = useAxios({
    httpParams: getOrderDetails({
      idOrden: id
    }),
    onSuccess: (response: any) => {
      const { data } = response;
      setData({
        //sort by listo. true first
        analisis: data.analisis.sort((a: any, b: any) => {
          if (a.listo && !b.listo) return -1;
          if (!a.listo && b.listo) return 1;
          return 0;
        })
      });
    }
  });

  if (error) {
    return (
      <Container>
        <DangerText>
          Ha ocurrido un error al cargar los datos, por favor intente nuevamente
        </DangerText>
      </Container>
    );
  }

  return (
    <Container>
      <TitleHeading>{`Órdene: ${data?.analisis[0].codigo || ''}`}</TitleHeading>
      <Subtitle>Información de la orden</Subtitle>

      {!loading ? (
        <>
          <TablaAnalisis data={data ? data.analisis : []} />
        </>
      ) : (
        <ScreenLoader />
      )}
    </Container>
  );
};

export default DetalleOrden;
