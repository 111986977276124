import styled from 'styled-components';

interface SeparatorProps {
  horizontal?: number;
  vertical?: number;
}

export const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const Subtitle = styled.h3`
  font-family: Kayak-Bold;
`;

export const Title = styled.h1`
  font-family: Kayak-Light;
`;

export const Stack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Separator = styled.div<SeparatorProps>`
  margin: ${props => (props.vertical ? props.vertical + 'vh' : '0')}
    ${props => (props.horizontal ? props.horizontal + 'vw' : '0')};
`;

export const DangerText = styled.p`
  ${props => props.theme.typo.sub1.bold};
  background-color: ${props => props.theme.colors.bgDanger};
  border: 1px solid ${props => props.theme.colors.borderDanger};
  color: ${props => props.theme.colors.textDanger};
  border-radius: ${props => props.theme.borderRadius.xs};
  padding: 0.5em 3em;
  text-align: center;
`;

export const BlurredBackground = styled.aside`
  position: absolute;
  left: 0;
  z-index: 400;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
`;
