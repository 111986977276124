import React from 'react';
import {
  Content,
  Body,
  Brand,
  Image,
  RedirectButton,
  TextButton
} from './styles';
import brand from 'images/logo_color.png';

interface Button {
  text: string;
  redirectTo: string;
}

interface Props {
  image: string;
  body?: React.ReactNode;
  button?: Button;
}

const Banner: React.FC<Props> = ({ image, body, button }) => {
  return (
    <Content>
      <Image src={image} />
      <Body>
        <Brand src={brand} />
        {!!body && body}
        {!!button && (
          <RedirectButton to={button.redirectTo}>
            <TextButton>{button.text}</TextButton>
          </RedirectButton>
        )}
      </Body>
    </Content>
  );
};

export default Banner;
