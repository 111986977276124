import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon as I } from 'ts-react-feather-icons';

export const WrapperLocations = styled.div`
  margin: 1.5em 6vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${props => props.theme.breakpoints.laptopL} {
    margin: 2.25em 6vw;
  }
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 45%;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 35%;
  }
`;

export const DateConteiner = styled.div`
  display: flex;
`;

export const DateInput = styled.div`
  box-shadow: 2px 2px 5px #999;
  border: 1px solid black;
  display: flex;
  width: 25%;
  height: 45px;
`;

export const Container = styled.div`
  box-shadow: 2px 2px 5px #999;
  border: 1px solid black;
  display: flex;
  height: 45px;
`;

export const Button = styled.button`
  width: 100%;
  font-family: Kayak-bold;
  margin-top: 1.8em;
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.shawdow
      : props.theme.colors.catedralRed};
  color: ${props => props.theme.colors.white};
  border: 0;
  box-shadow: 2px 2px 5px #999;
  font-size: 1em;
  padding: 0.75em;
  cursor: pointer;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 45%;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.25em;
    padding: 0.78em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 35%;
  }
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: '1.5em',
  color: props.theme.colors[props.color || 'white']
}))`
  align-self: center;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 2em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 2.5em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 3em;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding-left: 0.5em;
  font-family: Kayak-Regular;
  font-size: 1em;
  appearance: none;
  &::-moz-focus-inner {
    border: 0;
  }
  &:focus {
    outline: 0;
  }
  border: none;
`;

export const Card = styled.div`
  margin-top: 2em;
  width: 100%;
  box-shadow: 2px 2px 5px #999;
  display: flex;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 75%;
  }
`;

export const BodyCard = styled.div`
  width: 100%;
  display: flex;
  padding-left: 0.75em;
  padding-right: 0.75em;
  align-items: center;
  @media ${props => props.theme.breakpoints.laptop} {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
`;

export const TextStack = styled.div`
  display: flex;
  margin-bottom: 0.25em;
  @media ${props => props.theme.breakpoints.tablet} {
    margin-bottom: 0.5em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    margin-bottom: 1em;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25em;
  @media ${props => props.theme.breakpoints.tablet} {
    margin-bottom: 0.5em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    margin-bottom: 1em;
  }
`;

export const Stack = styled.div`
  margin-right: 1em;
  @media ${props => props.theme.breakpoints.laptop} {
    margin-right: 2em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    margin-right: 5em;
  }
`;

export const RegularText = styled.h2`
  font-size: 0.55em;
  font-weight: lighter;
  font-family: Kayak-Regular;
  margin: 0;
  margin-right: 0.3em;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.25em;
  }
`;
export const BoldText = styled.h2`
  font-family: Kayak-bold;
  font-size: 0.5em;
  margin: 0;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.25em;
  }
`;

export const IconCard = styled.div`
  width: 18%;
  background-color: ${props => props.theme.colors.catedralRed};
  display: flex;
  justify-content: center;
  padding: 1.25em 0em;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 18%;
    padding: 2em 0em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    padding: 3em 0em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    padding: 4em 0em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 13%;
    padding: 5em 0em;
  }
`;

export const OrdenCard = styled.div`
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props => props.theme.colors.catedralRed};
  margin-top: 2em;
  width: 100%;
  box-shadow: 2px 2px 5px #999;
  display: flex;
  color: white;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 75%;
  }
`;

export const OrdenBody = styled.div`
  padding: 0.75em;
  display: flex;
  align-items: center;
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 1em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    padding: 1.25em;
  }
`;

export const MoreDetail = styled.button`
  width: 100%;
  font-family: Kayak-bold;
  background-color: black;
  border-radius: ${props => props.theme.borderRadius.md};
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  border: 0;
  font-size: 0.5em;
  padding: 0.5em;
  cursor: pointer;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.25em;
  }
`;

export const HyperText = styled.a`
  margin: 1em 0em;
  width: 100%;
  font-family: Kayak-bold;
  cursor: pointer;
  text-align: center;
  font-size: 0.5em;
  text-decoration: underline;
  cursor: pointer;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.25em;
  }
`;

export const BackgroundTable = styled.div`
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props => props.theme.colors.darkBlue};
  margin-top: 0.5em;
  width: 100%;
  box-shadow: 2px 2px 5px #999;
  display: flex;
  color: white;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 75%;
  }
  border: 1px solid white;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5em;
  font-size: 0.5em;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 0.75em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1em;
  }
`;

export const HeadTable = styled.th<{ porcentage: number }>`
  border: 1px solid white;
  padding: 1em;
  text-align: center;
  font-family: Kayak-bold;
  border-top-left-radius: ${props => props.theme.borderRadius.md};
  border-top-right-radius: ${props => props.theme.borderRadius.md};

  width: ${props => props.porcentage}%;
`;

export const BodyTable = styled.td`
  border: 1px solid white;
  font-family: Kayak-regular;
  padding: 1em;
  text-align: center;
  border-radius: ${props => props.theme.borderRadius.md};
`;
