import React from 'react';
import { ExternalRedirect, RedirectButton, TextButton } from './styles';

interface Props {
  text: string;
  redirectTo: string;
  full?: boolean;
  external?: boolean;
}

const Button: React.FC<Props> = ({
  text,
  redirectTo,
  full = false,
  external = false
}) => {
  const externalRedirct = (to: string) => {
    window.open(to, '_blank');
  };

  if (external) {
    return (
      <ExternalRedirect full={full} onClick={() => externalRedirct(redirectTo)}>
        <TextButton>{text}</TextButton>
      </ExternalRedirect>
    );
  }
  return (
    <RedirectButton full={full} to={redirectTo}>
      <TextButton>{text}</TextButton>
    </RedirectButton>
  );
};

export default Button;
