import React from 'react';
import { DangerText } from 'styles/elements';
import { Container } from './styles';

export default () => {
  return (
    <>
      <Container>
        <DangerText>PAGINA NO DISPONIBLE</DangerText>
      </Container>
    </>
  );
};
