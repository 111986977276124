import React from 'react';
import { IconName } from 'ts-react-feather-icons';
import { Container, Icon } from './styles';

interface Props {
  icon: IconName;
  rounded?: boolean;
  color?: string;
  size: string;
  onClick: () => void;
}
const IconicButton: React.FC<Props> = ({
  color,
  size,
  icon,
  rounded = false,
  onClick
}) => {
  return (
    <Container rounded={rounded} onClick={() => onClick()}>
      <Icon color={color} name={icon} size={size} />
    </Container>
  );
};

export default IconicButton;
