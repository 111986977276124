import React, { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import TitleSection from 'components/titleSection';
import Autocomplete from 'components/autocomplete';
import ScreenLoader from 'components/screenLoader';
import { ListaAnalisis, Loader } from 'images';
import {
  WrapperLocations,
  Container,
  Content,
  Duration,
  Button,
  Icon,
  Selected,
  Subtitle,
  Submit,
  HeadTable,
  BodyTable,
  Table
} from './styles';
import LogoFixed from 'components/logoFixed';
import { getAllAnalisis } from 'utils/httpQueries';
import { DangerText } from 'styles/elements';

/* Components */

const Item = ({
  name,
  duration,
  action
}: {
  name: any;
  duration: any;
  action: Function;
}) => (
  <Container>
    <Content>
      {name.comercialName}
      <Duration>{duration}</Duration>
    </Content>
    <Button onClick={() => action(name)}>
      <Icon name="minus" />
    </Button>
  </Container>
);

export default () => {
  const [values, setValues] = useState<any[]>([]);
  const [submit, setSubmit] = useState<boolean>(false);
  const [totalValue, setTotalValue] = useState(0);
  const [data, setData] = useState<any>();

  const { loading, error } = useAxios({
    httpParams: getAllAnalisis(),
    onSuccess: (response: any) => {
      const analisis = response.analisis.filter((item: any) => item.publico === "Y");
      setData(analisis);
    }
  });

  if (error) {
    return (
      <WrapperLocations>
        <DangerText>
          Ha ocurrido un error al cargar los datos, por favor intente nuevamente
        </DangerText>
      </WrapperLocations>
    );
  }

  const onChange = (item: any) => {
    if (!values.includes(item)) {
      setValues([...values, item]);
    }
  };

  const onRemove = (item: any) => {
    if (values.includes(item)) {
      setValues(values.filter(value => value != item));
    }
  };

  const onSubmit = () => {
    let total = values.reduce((sum, a) => sum + a.Arancel[0].importeArancel, 0);
    setTotalValue(total);
    setSubmit(true);
  };

  return (
    <>
      <TitleSection text="Cotizar" image={ListaAnalisis} />

      <WrapperLocations>
        {!submit ? (
          <>
            <Subtitle>Encuentra el Analisis que necesitas</Subtitle>
            <Autocomplete
              selectProp={['comercialName']}
              items={data}
              minLenght={2}
              onChange={onChange}
              placeholder="Ingrese el analisis"
            />
            <Selected>
              {values &&
                values.length > 0 &&
                values.map((item, index) => (
                  <Item
                    key={index}
                    name={item}
                    duration={
                      item.deliveryTime != ''
                        ? `Listo en ${item.deliveryTime} dias`
                        : 'Se le informara cuando este listo'
                    }
                    action={onRemove}
                  />
                ))}
            </Selected>
            <Submit onClick={() => onSubmit()} disabled={values.length <= 0}>
              Cotizar
            </Submit>
          </>
        ) : (
          <>
            {values.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <HeadTable porcentage={65} scope="col">
                      Estudio
                    </HeadTable>
                    <HeadTable porcentage={35} scope="col">
                      Valor
                    </HeadTable>
                  </tr>
                </thead>
                <tbody>
                  {values.map((item, index) => (
                    <tr key={index}>
                      <BodyTable>{item.comercialName}</BodyTable>
                      <BodyTable>
                        {`${item.Arancel[0].importeArancel} Bs.`}
                      </BodyTable>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <HeadTable porcentage={65}>Total a Pagar</HeadTable>
                    <BodyTable>{`${totalValue} Bs.`}</BodyTable>
                  </tr>
                </tfoot>
              </Table>
            )}
            <Submit
              onClick={() => {
                setTotalValue(0);
                setSubmit(false);
              }}
            >
              Volver a cotizar
            </Submit>
          </>
        )}
      </WrapperLocations>
      {loading && <ScreenLoader />}
      <LogoFixed />
    </>
  );
};
