import styled from 'styled-components';
import { Title as T, Subtitle as S } from 'styles/elements';

interface ParagraphParams {
  bold?: boolean;
}

export const Content = styled.div`
  @media ${props => props.theme.breakpoints.tablet} {
    margin: 0em 8vw 2em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    margin: 0em 10vw 3em;
  }
`;

export const BannerWrapper = styled.div`
  position: relative;
  text-align: center;
`;

export const Title = styled(T)`
  margin: 0;
  margin-top: -20px;
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 3em;
    margin-top: -40px;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 5em;
    margin-top: -60px;
  }
`;

export const TextHeader = styled(S)`
  position: absolute;
  margin: 0;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1.75em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 2.75em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 4em;
  }
`;

export const Subtitle = styled.h2`
  text-align: center;
  font-family: Kayak-Bold;
  margin: 0;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1.75em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 2.75em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 4em;
  }
`;

export const Image = styled.img`
  width: 100%;
  height auto;
`;

export const TextSection = styled.div`
  margin-top: 2.75em;
  padding: 0 1em;
  text-align: justify;
`;

export const FlatSection = styled.div`
  padding: 0 1em;
  text-align: justify;
`;

export const Paragraph = styled.p<ParagraphParams>`
  font-family: ${props => (props.bold ? 'Kayak-Bold' : 'Kayak-Light')};
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.75em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 2.25em;
  }
`;

export const CardSection = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const MoralSection = styled.div`
  margin: 1em 0em;

  @media ${props => props.theme.breakpoints.tablet} {
    margin: 1.75em 0em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    margin: 3em 0em;
  }
`;

export const Wrapper = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-evenly;
  @media ${props => props.theme.breakpoints.tablet} {
    margin-top: 1.75em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    margin-top: 3em;
  }
`;

export const MiniCards = styled.div`
  width: 10%;
  box-shadow: 2px 2px 5px #999;
  justify-content: center;
  padding: 0.5em;
  align-items: center;
  display: flex;
`;

export const MiniCardImage = styled.img`
  width: 80%;
  height auto;
`;

export const Card = styled.div`
  box-shadow: 2px 2px 5px #999;
  width: 45%;
  font-size: 0.75em;
  margin-bottom: 1em;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1em;
  }
`;
export const Header = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const Body = styled.div`
  padding: 0.75em;
  text-align: justify;
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 1.25em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    padding: 1.75em;
  }
`;
