import React from 'react';
import { Container, Body, Text, Image, WrapperImage } from './styles';

interface Props {
  image: string;
  text?: string;
  redirect: string;
}

const MiniCard: React.FC<Props> = ({ image, text, redirect }) => {
  return (
    <Container to={redirect}>
      <WrapperImage>
        <Image src={image} />
      </WrapperImage>

      {text && (
        <Body>
          <Text>{text}</Text>
        </Body>
      )}
    </Container>
  );
};

export default MiniCard;
