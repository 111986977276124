import React from 'react';
import { ThemeProvider } from 'styled-components';
import UserProvider from 'hooks/useUser';
import { BrowserRouter as Router } from 'react-router-dom';
import common from 'styles/themes/common';
import Navigator from 'pages/Navigator';

export default function App() {
  return (
    <UserProvider>
      <ThemeProvider theme={common}>
        <Navigator />
      </ThemeProvider>
    </UserProvider>
  );
}
