import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  width: 80%;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 45%;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 35%;
  }
`;

export const Container = styled.div`
  box-shadow: 2px 2px 5px #999;
  border: 1px solid black;
  display: flex;
  height: 45px;
`;

export const Input = styled.input`
  width: 100%;
  padding-left: 0.5em;
  font-family: Kayak-light;
  font-size: 1em;
  appearance: none;

  &::-moz-focus-inner {
    border: 0;
  }
  &:focus {
    outline: 0;
  }
  border: none;
`;

export const BackDrop = styled.div`
  margin: 1.5em 6vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${props => props.theme.breakpoints.laptopL} {
    margin: 2.25em 6vw;
  }
`;

export const Submit = styled.button<{ loading: boolean }>`
  width: 100%;
  font-family: Kayak-bold;
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.shawdow
      : props.theme.colors.catedralRed};
  color: ${props => (props.loading ? 'transparent' : props.theme.colors.white)};
  border: 0;
  box-shadow: 2px 2px 5px #999;
  font-size: 1em;
  padding: 0.75em;
  cursor: pointer;
  position: relative;
  ${props =>
    props.loading &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 16px;
        height: 16px;

        border-radius: 50%;
        border: 4px solid transparent;
        border-top-color: ${props.theme.colors.white};
        animation: ${rotate} 1s ease infinite;
      }
    `}
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.25em;
    padding: 0.78em;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 0.85em;
  font-family: Kayak-bold;

  color: ${props => props.theme.colors.catedralRed};
`;
