import styled from 'styled-components';

export const Container = styled.div`
  margin: 1.5em 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media ${props => props.theme.breakpoints.laptopL} {
    margin: 2.25em 6vw;
  }
`;

export const TitleHeading = styled.h1`
  font-family: Kayak-bold;
  font-size: 2.3em;
  margin-bottom: 0;
`;

export const MarginTitle = styled.div`
  margin-bottom: 1.25em;
`;

export const Subtitle = styled.h2`
  font-family: Kayak-bold;
  font-size: 1.5em;
`;

export const Stack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export const StackItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media ${props => props.theme.breakpoints.laptop} {
    width: 60%;
  }
`;

export const Table = styled.table`
  width: 75%;
  border-collapse: collapse;
  margin-bottom: 1.5em;
  font-size: 0.5em;

  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 0.75em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1em;
  }
`;

export const TableTitle = styled.th<{ porcentage: number }>`
  border: 1px solid ${props => props.theme.colors.darkBlue};
  color: ${props => props.theme.colors.white};
  padding: 0.5em;
  text-align: center;
  font-family: Kayak-bold;
  width: ${props => props.porcentage}%;
  @media ${props => props.theme.breakpoints.laptop} {
    padding: 1em;
  }
`;

export const HeadTable = styled.thead`
  background-color: ${props => props.theme.colors.catedralRed};
`;

export const BodyTable = styled.td`
  border: 1px solid ${props => props.theme.colors.darkBlue};
  font-family: Kayak-regular;
  padding: 0.65em;
  text-align: center;
`;

export const TableRow = styled.tr<{ even: boolean }>`
  background-color: ${props => (props.even ? '#F0F0F0' : '#f8d7da')};
  color: ${props => props.theme.colors.darkBlue};
`;
