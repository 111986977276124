import React from 'react';
import { Card, AlertIcon, Body, Header, Title, Message } from './styles';

interface Props {
  title: string;
  message: string;
}

const EmptyCard: React.FC<Props> = ({ title, message }) => {
  return (
    <Card>
      <AlertIcon />
      <Header>
        <Title>{title}</Title>
      </Header>
      <Body>
        <Message>{message}</Message>
      </Body>
    </Card>
  );
};

export default EmptyCard;
