import styled from 'styled-components';

export const WrapperLocations = styled.div`
  margin: 1.5em 6vw;

  @media ${props => props.theme.breakpoints.tablet} {
    margin: 1.5em 6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${props => props.theme.breakpoints.laptopL} {
    margin: 2.25em 6vw;
  }
`;
