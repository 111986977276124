import React, { useEffect, useState } from 'react';

import {
  Wrapper,
  Container,
  Image,
  ImageContainer,
  Controller,
  Icon,
  DotsContainer,
  Dot
} from './styles';

const makeCarousel = require('react-reveal/makeCarousel');
const Flip = require('react-reveal/Slide');

interface Items {
  imageUrl: string;
  text?: string;
}

interface Props {
  items: Items[];
}
const CarouselContainer = ({ total, position, handleClick, children }: any) => (
  <Container>
    {children}

    <Controller onClick={handleClick} left={true} data-position={position - 1}>
      <Icon name={'chevron-left'} />
    </Controller>
    <Controller onClick={handleClick} left={false} data-position={position + 1}>
      <Icon name={'chevron-right'} />
    </Controller>
    <DotsContainer>
      {Array(...Array(total)).map((item, index) => (
        <Dot
          key={index}
          activate={position === index}
          onClick={handleClick}
          data-position={index}
        />
      ))}
    </DotsContainer>
  </Container>
);

const CarouselUI = makeCarousel(CarouselContainer);

const Carousel: React.FC<Props> = ({ items }) => {
  const [current, setCurrent] = useState<number>(0);

  const prevSlide = () => {
    setCurrent(prevValue =>
      prevValue === 0 ? items.length - 1 : prevValue - 1
    );
  };
  const nextSlide = () => {
    setCurrent(prevValue =>
      prevValue === items.length - 1 ? 0 : prevValue + 1
    );
  };

  useEffect(() => {
    const carouselInit = setInterval(nextSlide, 5000);
    return () => clearInterval(carouselInit);
  }, []);

  return (
    <CarouselUI swipe={true}>
      {items.map((item, index) => (
        <Flip key={index} right>
          <ImageContainer>
            <Image src={item.imageUrl} />
          </ImageContainer>
        </Flip>
      ))}
    </CarouselUI>
  );
};

export default Carousel;
