import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 2em;
  @media ${props => props.theme.breakpoints.laptopL} {
    margin-bottom: 4em;
  }
`;
export const Body = styled.div<{ position: number }>`
  padding: 0em 1em;
  display: flex;
  align-items: center;
  width: 80%;
  order: ${props => props.position};
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 0em 1.5em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    padding: 0em 3.5em;
  }
`;
export const Text = styled.p`
  font-family: Kayak-Light;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1.2em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.75em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 2.25em;
  }
`;
export const ImageWrapper = styled.div<{ position: number }>`
  order: ${props => props.position};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 0.5em;
  width: 20%;
  background-color: ${props => props.theme.colors.catedralRed};
`;
export const Image = styled.img`
  width: 80%;
  height: auto;
`;
