import mapStyles from './mapStyle';

export const containerStyle = {
  width: '100%',
  height: '100%'
};

export const options = {
  disableDefaultUI: false
};
