import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16vw;
  @media ${props => props.theme.breakpoints.tablet} {
    height: 12vw;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    height: 10vw;
  }
`;

export const Title = styled.h2`
  ${props => props.theme.typo.h5.bold};
  position: absolute;
  text-align: center;
  font-weight: normal;
  margin: 0;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 2.5rem;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 3rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  resize-mode: cover;
`;
