import React from 'react';

import FlatCard from 'components/flatCard';
import LogoFixed from 'components/logoFixed';
import {
  Conocenos,
  FlatCard1,
  FlatCard2,
  FlatCard3,
  FlatCard4,
  Resultado,
  ListaAnalisis,
  Valor1,
  Valor2,
  Valor3,
  Valor4,
  Valor5
} from 'images';
import {
  Content,
  Image,
  BannerWrapper,
  Title,
  TextSection,
  FlatSection,
  Paragraph,
  Card,
  Header,
  TextHeader,
  Subtitle,
  MoralSection,
  Body,
  CardSection,
  MiniCards,
  MiniCardImage,
  Wrapper
} from './styles';

interface CardItemParams {
  image: any;
  text: string;
  title: string;
}

const CardItem = ({ image, text, title }: CardItemParams) => {
  return (
    <Card>
      <Header>
        <Image src={image} />
        <TextHeader>{title}</TextHeader>
      </Header>
      <Body>
        <Paragraph>{text}</Paragraph>
      </Body>
    </Card>
  );
};

export default () => {
  return (
    <Content>
      <BannerWrapper>
        <Image src={Conocenos} />
        <Title>Conócenos</Title>
      </BannerWrapper>
      <TextSection>
        <Paragraph>
          Laboratorio Catedral es una empresa de servicios médicos
          laboratoriales en Patología quirúrgica y análisis clínicos que brinda
          calidad en el diagnóstico mediante la aplicación actualizada de
          formación y tecnología personal competente y proveedores confiables
          que garanticen una excelente atención y satisfacción de las
          necesidades de sus clientes.
        </Paragraph>
        <Paragraph>
          Así el laboratorio se compromete a ejecutar el cumplimiento de la
          mejora continua de la eficacia de sus sientas de gestión de calidad y
          la capacitación permanente de sus recursos humanos, para la
          implementación de buenas prácticas profesionales dentro de los
          principios éticos dirigidos a preservar la vida.
        </Paragraph>
        <Paragraph bold>En este sentido, se tiene un compromiso con:</Paragraph>
      </TextSection>
      <FlatSection>
        <FlatCard
          image={FlatCard1}
          text={
            'El cliente, para que se sienta satisfecho con la atencion y seguro del informe recibido.'
          }
          position="left"
        />
        <FlatCard
          image={FlatCard2}
          text={
            'Organizar, ordenar e incentivar para mejorar continuamente los procesos.'
          }
          position="right"
        />
        <FlatCard
          image={FlatCard3}
          text={
            'Desarrollar los recursos humanos, mediante la asimilación de los principios que rigen la empresa y la capacitación permanente.'
          }
          position="left"
        />
        <FlatCard
          image={FlatCard4}
          text={
            'Mejorar la captación de clientes y optimizar el uso de los recursos mediante la administración eficiente.'
          }
          position="right"
        />
      </FlatSection>
      <CardSection>
        <CardItem
          image={Resultado}
          title="Mision"
          text="Brindar a nuestra sociedad servicios de alta calidad en Análisis clínicos y Anatomía Patológica en cada uno de nuestros laboratorios mediante la aplicación actualizada de información y tecnología, personal médico y bioquímico competente junto a proveedores confiables, que garantizan una excelente atención y satisfacción de sus clientes."
        />
        <CardItem
          image={ListaAnalisis}
          title="Vision"
          text="Llegar a ser la red de laboratorios más moderna en Bolivia, actualizada y de refencia en Análisis Clinicos y Anatomia Patológica, con un Sistema de Gestión de Calidad implementado cumpliendo con la mejora continua y la capacitación permanente de sus recursos humanos, que aseguren una excelente atención de sus clientes médicos y pacientes."
        />
      </CardSection>
      <MoralSection>
        <Subtitle>Nuestros Valores</Subtitle>
        <Wrapper>
          <MiniCards>
            <MiniCardImage src={Valor1} />
          </MiniCards>
          <MiniCards>
            <MiniCardImage src={Valor2} />
          </MiniCards>
          <MiniCards>
            <MiniCardImage src={Valor3} />
          </MiniCards>
          <MiniCards>
            <MiniCardImage src={Valor4} />
          </MiniCards>
          <MiniCards>
            <MiniCardImage src={Valor5} />
          </MiniCards>
        </Wrapper>
      </MoralSection>
      <LogoFixed />
    </Content>
  );
};
