import styled from 'styled-components/macro';
import { Icon as I } from 'ts-react-feather-icons';

export const Card = styled.div`
  font-family: Kayak-bold;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1.35em 1em 1em;
  margin-top: 1.25em;
  color: ${props => props.theme.colors.darkBlue};
`;

export const Title = styled.h2`
  margin-bottom: 0;
`;

export const Message = styled.h4`
  text-align: center;
`;

export const Header = styled.div``;

export const Body = styled.div``;

export const AlertIcon = styled(I).attrs(props => ({
  name: 'search',
  size: '100px',
  color: props.theme.colors.darkBlue
}))``;
