import styled from 'styled-components';

export const CardsContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 8vw 0;
  padding: 0 0.5em;
  @media ${props => props.theme.breakpoints.laptop} {
    margin: 8vw 4vw;
  }
`;

export const MiniCards = styled.div`
  display: flex;
  justify-content: center;
  margin: 8vw 0;
`;
