import styled from 'styled-components';
import B from 'components/button';
import { Icon as I } from 'ts-react-feather-icons';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 2px 2px 5px #999;
  margin-bottom: 2em;
  @media ${props => props.theme.breakpoints.tablet} {
    width: 65%;
    height 25vw;
  }
  @media ${props => props.theme.breakpoints.laptop} {
  
  }
  
`;

export const MapWrapper = styled.div`
  width: 50%;
  min-width: 50%;
`;

export const CardBody = styled.div`
  padding: 0.75em;
`;

export const Title = styled.p`
  ${props => props.theme.typo.body1.bold};
  display: flex;
  align-items: center;
  font-size: 0.95em;
  color: ${props => props.theme.colors.catedralRed};
  line-height: 20px;
  margin: 0;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1.25em;
  }

  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.5em;
    margin: 0;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 2.5em;
  }
`;

export const Text = styled.p`
  ${props => props.theme.typo.body1.regular};

  flex-grow: 1;
  margin: 0;
  margin-bottom: 0.75em;
  line-height: 15px;
  font-weight: 580;
  font-size: 0.75em;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 0.95em;
    margin-bottom: 1.5em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-weight: 400;
    line-height: 1.15em;
    font-size: 1em;
    margin: 0;
    margin-top: 1em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.25em;
  }

  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 2.2em;
  }
`;

export const Image = styled.img`
  border-top-left-radius: ${props => props.theme.borderRadius.xs};
  border-top-right-radius: ${props => props.theme.borderRadius.xs};
  width: 100%;
  @media ${props => props.theme.breakpoints.laptop} {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 70%;
    height: 100%;
    flex-direction: row;
  }
`;

export const Button = styled(B)``;

export const IconContainer = styled.div`
  display: flex;
  margin-bottom: 0.5em;
  @media ${props => props.theme.breakpoints.laptop} {
    padding-top: 1em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    padding-top: 3em;
  }
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: '0.8em',
  color: props.theme.colors.catedralRed
}))`
  margin-right: 1.25vw;
  align-self: center;
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.5em;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 2.5em;
  }
`;

export const PhoneIcon = styled(I).attrs(props => ({
  name: 'phone',
  size: '1em',
  color: 'black'
}))`
  margin-right: 1.25vw;
  align-self: center;
`;
