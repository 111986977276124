import { Role } from 'components/privateRoute';
import React, { useState, createContext, useContext, useEffect } from 'react';
import { getMedicoProfile } from 'utils/httpQueries';
import useAxios from './useAxios';

interface userInfo {
  id: number;
  name: string;
  lastname: string;
  role: Role;
}

interface IUserInfo extends userInfo {
  token: string;
}

interface ContextProps {
  userInfo: userInfo | null;
  setUserInfo: any;
}

const Context = createContext<ContextProps>({
  userInfo: null,
  setUserInfo: () => {}
});

export const useUser = () => {
  const { userInfo, setUserInfo } = useContext(Context);
  const logOut = () => {
    setUserInfo(null);
    localStorage.removeItem('token');
  };

  const login = (user: IUserInfo) => {
    const { token, ...userInfo } = user;
    localStorage.setItem('token', token);
    setUserInfo(userInfo);
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    const currentTimeSeconds = Math.round(+new Date() / 1000);
    const tokenExpireTime = JSON.parse(atob(token.split('.')[1])).exp;

    if (tokenExpireTime < currentTimeSeconds) {
      logOut();
      return false;
    }

    return true;
  };

  return {
    userInfo,
    login,
    logOut,
    isAuthenticated
  };
};

export default ({ children }: { children: any }) => {
  const [userInfo, setUserInfo] = useState<userInfo | null>(null);
  const { loading, setHttpParams } = useAxios({
    onSuccess: (response: any) => {
      const { id, nombre, apellido, type } = response.data;
      setUserInfo({
        id,
        name: nombre,
        lastname: apellido,
        role: type
      });
    },
    onError: (error: any) => {}
  });

  useEffect(() => {
    if (localStorage.getItem('token')) {
      console.log('entra aca ');
      setHttpParams(getMedicoProfile());
    }
  }, []);

  return (
    <Context.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </Context.Provider>
  );
};
