const palette = {
  catedralRed: '#E70103',
  darkBlue: '#070c24',
  blue: '#255C99',
  white: '#FFFFFF',
  shawdow: '#332f2d',
  bgDanger: '#f8d7da',
  textDanger: '#721c24',
  borderDanger: '#f5c6cb',
  warning: '#ffc107'
};

export default palette;
