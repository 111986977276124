import React from 'react';
import { Content, CardBody, Title, Image, Button } from './styles';

interface IButton {
  text: string;
  redirectTo: string;
  external?: boolean;
}

interface Props {
  image: string;
  text: string;
  button: IButton;
}
const Card: React.FC<Props> = ({ image, text, button }) => {
  return (
    <Content>
      <Image src={image} />
      <CardBody>
        <Title>{text}</Title>
        <Button
          text={button.text}
          redirectTo={button.redirectTo}
          full={true}
          external={button.external}
        />
      </CardBody>
    </Content>
  );
};

export default Card;
