import styled from 'styled-components';

export const BlurredBackground = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  z-index: 400;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
`;
