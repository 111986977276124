import React from 'react';
import Map from './map';
import {
  Content,
  CardBody,
  MapWrapper,
  Title,
  IconContainer,
  Icon,
  Text,
  PhoneIcon
} from './styles';

interface Props {
  title: string;
  address: string;
  phoneNumber: string;
  lat: number;
  lng: number;
}
const MapCard: React.FC<Props> = ({
  title,
  phoneNumber,
  address,
  lat,
  lng
}) => {
  return (
    <Content>
      <MapWrapper>
        <Map mapName={title} lat={lat} lng={lng} />
      </MapWrapper>

      <CardBody>
        <IconContainer>
          <Icon name="map-pin" />
          <Title>{title}</Title>
        </IconContainer>
        <Text>
          {address} <br />
          <br /> Horario de atencion: <br />
          Lun - Vie 7:00 – 19:00 hrs.
          <br />
          Sab 07:00 – 12:30 <br />
          <IconContainer>
            <PhoneIcon />
            {phoneNumber}
          </IconContainer>
        </Text>
      </CardBody>
    </Content>
  );
};

export default MapCard;
