import { useUser } from 'hooks/useUser';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

export enum Role {
  MEDICO = 'MEDICO',
  USER = 'USER'
}

interface Props {
  element: any;
  path?: string;
  redirectTo?: string;
  isProtected?: boolean;
  exact?: boolean;
}

const PrivateRoute = ({
  element,
  redirectTo = '/',
  isProtected = true,
  ...rest
}: Props) => {
  const { isAuthenticated } = useUser();

  if (isProtected && !isAuthenticated()) {
    return <Redirect to={redirectTo} />;
  }
  return <Route component={element} {...rest} />;
};

export default PrivateRoute;
