import React from 'react';
import { Loader } from 'images';
import { BlurredBackground } from './styles';

export default () => {
  return (
    <BlurredBackground>
      <Loader />
    </BlurredBackground>
  );
};
