import styled from 'styled-components';
import { Icon as I } from 'ts-react-feather-icons';
import { NavLink as N } from 'react-router-dom';

export const Container = styled.div`
  position: absolute;
  box-shadow: 2px 2px 5px #999;
  left: 0;
  width: 60vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.catedralRed};
  @media ${props => props.theme.breakpoints.tablet} {
    width: 30vw;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    width: 25vw;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  padding-left: 3em;
  @media ${props => props.theme.breakpoints.laptop} {
    padding-left: 5em;
  }
`;

export const NavLink = styled(N).attrs(props => ({
  activeStyle: {
    backgroundColor: props.theme.colors.darkBlue,
    transform: 'scale(1.1)'
  }
}))`
  text-decoration: none;
  :hover {
    background-color: ${props => props.theme.colors.darkBlue};
    transform: scale(1.1);
  }
  padding: 0.15em;
  margin-bottom: 0.6em;
  border-top-left-radius: ${props => props.theme.borderRadius.xl};
  border-bottom-left-radius: ${props => props.theme.borderRadius.xl};
`;

export const ExternalRedirect = styled.a`
  border: 0;
  text-decoration: none;
  :hover {
    background-color: ${props => props.theme.colors.darkBlue};
    transform: scale(1.1);
  }
  padding: 0.15em;
  margin-bottom: 0.6em;
  border-top-left-radius: ${props => props.theme.borderRadius.xl};
  border-bottom-left-radius: ${props => props.theme.borderRadius.xl};
`;

export const TextNav = styled.p`
  ${props => props.theme.typo.body2.bold};
  color: ${props => props.theme.colors.white};

  font-size: 1em;
  text-align: justify;
  font-weight: normal;
  line-height: 8px;
  padding-left: 0.7em;
  color: ${props => props.theme.colors.white};

  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.25rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: 30,
  color: props.theme.colors.white
}))``;
