import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Header from 'components/header';

import Home from './home';
import AboutUs from './aboutUs';
import Results from './results';
import Locations from './locations';
import Analisis from './analisis';
import Ordenado from './analisis/categorias';
import Categorias from './analisis/categorias';
import E404 from './error';
import Cotizar from './cotizar';
import Medicos from './medicos';

export default () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/resultados" component={Results} />
        <Route path="/nosotros" component={AboutUs} />
        <Route path="/sucursales" component={Locations} />
        <Route path="/analisis" component={Analisis} />
        <Route path="/cotizar" component={Cotizar} />
        <Route path="/medicos" component={Medicos} />
        <Route path="*" component={E404} />
      </Switch>
    </BrowserRouter>
  );
};
