import React from 'react';
import { LogoWhatsapp } from 'images';
import { Content, Image } from './styles';

const LogoFixed: React.FC = () => {
  const openWhatsapp = () => {
    window.open('https://wa.me/59162239866', '_blank');
  };
  return (
    <Content onClick={() => openWhatsapp()}>
      <Image src={LogoWhatsapp} />
    </Content>
  );
};

export default LogoFixed;
