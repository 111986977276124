import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import sliderData from 'mocks/sliderData';
/* Components */
import Banner from 'components/banner';
import LogoFixed from 'components/logoFixed';
import Card from 'components/homeCard';
import Footer from 'components/footer';
import MiniCard from 'components/miniCard';
import Carousel from 'components/carousel';
/* Images */
import ImageBanner from 'images/banner_home.png';
import ImageCard1 from 'images/home_card1.png';
import ImageCard2 from 'images/home_card2.png';
import EventLogo from 'images/eventos_ico.png';
import LocationLogo from 'images/location_ico.png';
import ServiceLogo from 'images/servicios_ico.png';
/* Styles components */
import { TextBody } from 'components/banner/styles';
import { Separator } from 'styles/elements';
import { CardsContent, MiniCards } from './styles';

const BannerBody = () => (
  <TextBody>
    Todos tus <strong>resultados</strong> en un solo lugar
  </TextBody>
);

export default () => {
  return (
    <>
      <Banner
        image={ImageBanner}
        body={<BannerBody />}
        button={{
          text: 'Ver mis resultados',
          redirectTo: '/resultados'
        }}
      />
      <CardsContent>
        <Card
          text="Cotizacion de analisis"
          image={ImageCard1}
          button={{ text: 'COTIZAR', redirectTo: '/cotizar' }}
        />

        <Card
          text="Tomas a domicilio, oficina o centro de salud"
          image={ImageCard2}
          button={{
            text: 'SACAR TURNO',
            redirectTo: 'https://bit.ly/tomasadomicilio',
            external: true
          }}
        />
      </CardsContent>
      <Carousel items={sliderData} />
      <MiniCards>
        <MiniCard
          redirect={'/sucursales'}
          text="Nuestras Sucursales"
          image={LocationLogo}
        />
        <Separator horizontal={2} />
        <MiniCard
          redirect={'/analisis'}
          text="Lista de Analisis"
          image={ServiceLogo}
        />
        <Separator horizontal={2} />
        <MiniCard
          redirect={'/sucursales'}
          text="Sobre Nosotros"
          image={EventLogo}
        />
      </MiniCards>
      <Footer />
      <LogoFixed />
    </>
  );
};
