import typo from './typo';
import breakpoints from './breakpoints';
import colors from './colors';

const borderRadius = {
  xs: '0.25em',
  sm: '0.5em',
  md: '1em',
  lg: '1.25em',
  xl: '1.5em'
};

export default {
  typo,
  borderRadius,
  breakpoints,
  colors
};
