import styled from 'styled-components';
import { Icon as I } from 'ts-react-feather-icons';

interface ContainerProps {
  rounded: boolean;
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: ${props => (props.rounded ? '50%' : `0px`)};
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: props.size,
  color: props.theme.colors[props.color || 'white']
}))`
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1.4em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1.6em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.8em;
  }
`;
