import styled from 'styled-components';
import B from 'components/button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 45%;
  border-radius: ${props => props.theme.borderRadius.xs};
  box-shadow: 2px 2px 5px #999;
  @media ${props => props.theme.breakpoints.laptop} {
    flex-direction: row;
    max-width: 40%;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 1.25em 0.75em 0.75em;
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 3em 2.75em 1em;
    width: 75%;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    height: 94%;
    padding: 0em 1em 1em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    height: 94%;
    padding: 0em 1.25em 1.25em;
  }
`;

export const Title = styled.p`
  ${props => props.theme.typo.body1.regular};
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  margin-bottom: 0.75em;
  text-align: center;
  font-weight: 580;
  font-size: 1.1em;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1.25em;
    margin-bottom: 1.5em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-weight: 400;
    line-height: 1.15em;
    margin: 0;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.5em;
    margin: 0;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 2.2em;
  }
`;

export const Image = styled.img`
  border-top-left-radius: ${props => props.theme.borderRadius.xs};
  border-top-right-radius: ${props => props.theme.borderRadius.xs};
  width: 100%;
  @media ${props => props.theme.breakpoints.laptop} {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 70%;
    height: 100%;
    flex-direction: row;
  }
`;

export const Button = styled(B)``;
