import React from 'react';
import { Content, Title, Image } from './styles';

interface Props {
  image: string;
  text: string;
}

const TitleSection: React.FC<Props> = ({ image, text }) => {
  return (
    <Content>
      <Image src={image}></Image>
      <Title>{text}</Title>
    </Content>
  );
};

export default TitleSection;
