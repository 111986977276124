import React from 'react';
import logoCompany from 'images/logo_company.png';
import {
  Container,
  Nav,
  NavLink,
  TextNav,
  IconContainer,
  Icon,
  ExternalRedirect
} from './styles';
import { type } from 'os';

type Directions = {
  label: string;
  redirect: string;
  external?: boolean;
};
interface Props {
  onClose: Function;
}

const directions: Directions[] = [
  {
    label: 'Inicio',
    redirect: '/'
  },
  {
    label: 'Resultados',
    redirect: '/resultados'
  },
  {
    label: 'Cotizar',
    redirect: '/cotizar'
  },
  {
    label: 'Lista de analisis',
    redirect: '/analisis'
  },
  {
    label: 'Nosotros',
    redirect: '/nosotros'
  },
  {
    label: 'Sucursales',
    redirect: '/sucursales'
  },
  {
    label: 'Medicos',
    redirect: '/medicos'
  },
  {
    label: 'Seguros',
    redirect: 'http://200.119.213.75/catedral/externo/consultasdelseguro/',
    external: true
  }
];

const Item = ({
  redirect,
  text,
  onClose,
  external = false
}: {
  redirect: string;
  text: string;
  onClose: Function;
  external?: boolean;
}) => {
  const externalRedirct = (to: string) => {
    window.open(to, '_blank');
  };

  if (external) {
    return (
      <ExternalRedirect onClick={() => externalRedirct(redirect)}>
        <TextNav>{text}</TextNav>
      </ExternalRedirect>
    );
  }
  return (
    <NavLink to={redirect} exact={true} onClick={() => onClose()}>
      <TextNav>{text}</TextNav>
    </NavLink>
  );
};

const Burger: React.FC<Props> = ({ onClose }) => {
  return (
    <Container>
      <Nav>
        {directions.map((item, index) => (
          <Item
            key={index}
            redirect={item.redirect}
            text={item.label}
            onClose={onClose}
            external={item.external}
          />
        ))}
      </Nav>
    </Container>
  );
};

export default Burger;
