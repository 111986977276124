import styled from 'styled-components/';

interface ContentProps {
  full?: boolean;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => (props.full ? '100%' : '47%')};
`;

export const Label = styled.label`
  justify-content: center;
  width: 100%;
  align-self: center;
  font-family: Kayak-regular;
  font-size: 0.99em;
  margin-bottom: 0.5em;
`;
