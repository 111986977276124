import styled from 'styled-components/';
import { NavLink } from 'react-router-dom';

export const Content = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 5px #999;
  height: 38.78vw;
`;

export const Body = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 30%;
  align-items: center;
  margin-left: 16vw;
`;

export const TextBody = styled.h2`
  ${props => props.theme.typo.h3.light};
  font-size: 0.75rem;
  line-height: 1.25em;
  margin: 0.25em 0 0.75em;
  text-align: center;
  font-weight: normal;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1.5rem;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 2rem;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 2.75rem;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 3.75rem;
  }
`;

export const RedirectButton = styled(NavLink)`
  text-decoration: none;
  background-color: ${props => props.theme.colors.catedralRed};
  border-color: ${props => props.theme.colors.catedralRed};
  border-radius: ${props => props.theme.borderRadius.xs};
  border: 1px solid ${props => props.theme.colors.catedralRed};
  box-shadow: 2px 2px 5px #999;
`;
export const TextButton = styled.p`
  ${props => props.theme.typo.body2.light};
  font-size: 0.65em;
  line-height: 0.5em;
  margin: 0.15rem;
  padding: 0.25em;
  text-align: center;
  color: ${props => props.theme.colors.white};
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 0.85em;
    padding: 0.5em;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1rem;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.5rem;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 2rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  object-fit: cover;
`;

export const Brand = styled.img`
  width: 16vw;
  aspect-ratio: 1;
`;
