import styled from 'styled-components/macro';
import { Icon as I } from 'ts-react-feather-icons';

interface PageItemProps {
  borderRLeft?: boolean;
  borderRRight?: boolean;
  selected?: boolean;
}

export const Container = styled.nav`
  font-family: Kayak-bold;
  margin-top: 1em;
`;
export const PageContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
`;
export const PageItem = styled.li<PageItemProps>`
  padding: 0.5em;
  display: flex;
  background-color: ${props =>
    props.selected ? props.theme.colors.catedralRed : 'white'};
  border-top-left-radius: ${props =>
    props.borderRLeft ? props.theme.borderRadius.md : 0};
  border-bottom-left-radius: ${props =>
    props.borderRLeft ? props.theme.borderRadius.md : 0};
  border-top-right-radius: ${props =>
    props.borderRRight ? props.theme.borderRadius.md : 0};
  border-bottom-right-radius: ${props =>
    props.borderRRight ? props.theme.borderRadius.md : 0};

  border: 2px solid white;
  border-color: ${props => props.theme.colors.darkBlue};
  margin-left: 0.5em;
  box-shadow: 2px 2px 5px #999;
  ${props =>
    !props.borderRLeft && !props.borderRRight
      ? `border-radius: ${props.theme.borderRadius.sm}!important`
      : ''};

  cursor: pointer;
`;

export const Separator = styled.div<{ horizontal: number }>`
  margin: 0 ${props => (props.horizontal ? props.horizontal + 'vw' : '0')};
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: '1.20em',
  color: props.theme.colors[props.color || 'white']
}))`
  align-self: center;
`;
