import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import IconicButton from 'components/iconicButton';
import { capitalize } from 'helpers/text';

import {
  BodyTable,
  Container,
  HeadTable,
  Stack,
  StackItem,
  Subtitle,
  Table,
  TableRow,
  TableTitle,
  TitleHeading
} from '../styles';
import useAxios from 'hooks/useAxios';
import { getPacienteDetail } from 'utils/httpQueries';
import ScreenLoader from 'components/screenLoader';
import { DangerText } from 'styles/elements';

/* Components */

interface Props {
  entity: any;
  fieldsR: string[];
  fieldsL: string[];
}

interface Historico {
  paciente: {
    id: number;
    nombre: string;
    apellidoP: string;
    apellidoM: string;
    sexo: string;
  };
  ordenes: {
    id: number;
    codigo: string;
    fecha: string;
    gestion: number;
  }[];
}

const goOrder = (gestion: any, codigo: any) => {
  const urlOrder = `http://200.119.213.75/catedral/externo/consultasdelmedicosolicitante/resultados_original_pdf.php?id_tipoanalisis=3&codigo=${codigo}&id_gestion=${gestion}`;
  window.open(urlOrder, '_blank');
};

const TablePacientes = ({ data }: { data: any[] }) => {
  const history = useHistory();

  // redirect to paciente id
  const goOrderDetail = (id: number) => {
    history.push(`/medicos/orden/${id}`);
  };

  return (
    <Table>
      <HeadTable>
        <tr>
          <TableTitle porcentage={3} scope="col">
            Orden
          </TableTitle>
          <TableTitle porcentage={8} scope="col">
            Fecha
          </TableTitle>
          <TableTitle porcentage={4} scope="col">
            Estado
          </TableTitle>
          <TableTitle porcentage={4} scope="col">
            Ver Detalle
          </TableTitle>
          <TableTitle porcentage={4} scope="col">
            Imprimir PDF
          </TableTitle>
        </tr>
      </HeadTable>
      <tbody>
        {data.map((item: any, index: number) => (
          <TableRow key={item.id} even={index % 2 == 0}>
            <BodyTable>{item.codigo}</BodyTable>
            <BodyTable>{item.fecha}</BodyTable>
            <BodyTable>
              {item.cantidadAnalisis === item.cantidadAnalisisListo
                ? 'Listo'
                : `Pendiente (${item.cantidadAnalisisListo}/${item.cantidadAnalisis})`}
            </BodyTable>

            <BodyTable>
              <Stack>
                <IconicButton
                  icon="eye"
                  size={'1.2em'}
                  color={'darkBlue'}
                  onClick={() => goOrderDetail(item.id)}
                />
              </Stack>
            </BodyTable>
            <BodyTable>
              <Stack>
                <IconicButton
                  icon="printer"
                  size={'1.2em'}
                  color={'darkBlue'}
                  onClick={() => goOrder(item.gestion, item.codigo)}
                />
              </Stack>
            </BodyTable>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

const DetallePaciente: React.FC<Props> = ({ entity, fieldsL, fieldsR }) => {
  const { id } = useParams<{ id: any }>();
  const [data, setData] = React.useState<Historico | null>(null);

  const { loading, error } = useAxios({
    httpParams: getPacienteDetail({
      idPaciente: id
    }),
    onSuccess: (response: any) => {
      setData({
        paciente: response.data.paciente,
        ordenes: response.data.ordenes
      });
    }
  });

  if (error) {
    return (
      <Container>
        <DangerText>
          Ha ocurrido un error al cargar los datos, por favor intente nuevamente
        </DangerText>
      </Container>
    );
  }

  return (
    <Container>
      <TitleHeading>{'Órdenes Realizadas'}</TitleHeading>

      {!loading ? (
        <>
          {data && (
            <Subtitle>{`${data.paciente.nombre} ${data.paciente.apellidoP}`}</Subtitle>
          )}
          <StackItem>
            <TablePacientes data={data ? data.ordenes : []} />
          </StackItem>
        </>
      ) : (
        <ScreenLoader />
      )}
    </Container>
  );
};

export default DetallePaciente;
