import React from 'react';
import TitleSection from 'components/titleSection';

import ImageTitle from 'images/registro.png';
import MapCard from 'components/mapCard';
import { WrapperLocations } from './styles';
import { Separator } from 'styles/elements';
import LogoFixed from 'components/logoFixed';
/* Components */

export default () => {
  return (
    <>
      <TitleSection text="Sucursales" image={ImageTitle} />
      <WrapperLocations>
        <MapCard
          title="Central"
          address="Calle Andres Ibañez #115"
          phoneNumber="(333)-3305"
          lat={-17.776558}
          lng={-63.183581}
        />
        <MapCard
          title="Sucursal #1"
          address="Av. Hernando Sanabria Prolongacion Centenario, 2do anillo #114"
          phoneNumber="(353)-4242"
          lat={-17.779371}
          lng={-63.194236}
        />
        <MapCard
          title="Sucursal #2"
          address="3er anillo externo Casi esq. Paragua"
          phoneNumber="(337)-4343"
          lat={-17.769205}
          lng={-63.155458}
        />
      </WrapperLocations>
      <LogoFixed />
    </>
  );
};
