import { useState, useEffect } from 'react';
import { useUser } from './useUser';
import axios from 'axios';
import { httpParams } from 'utils/httpQueries';

interface useAxiosProps {
  httpParams?: httpParams;
  onSuccess?: (response: any) => void;
  onError?: (error: any) => void;
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const useAxios = (data: useAxiosProps | undefined = undefined) => {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(data?.httpParams);
  const { logOut } = useUser();

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        logOut();
      }
      return Promise.reject(error);
    }
  );

  const fetchData = () => {
    if (params) {
      const { method, url, config, body } = params;

      axios({
        method: method,
        url: url,
        data: body,
        headers: config ? config.headers : {}
      })
        .then(res => {
          data?.onSuccess?.(res.data);
          setResponse(res.data);
        })
        .catch(err => {
          data?.onError?.(err);
          setError(err);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (params) {
      setLoading(true);
      fetchData();
    }
  }, [params]);

  return {
    response,
    error,
    loading,
    setHttpParams: (config: httpParams) => {
      setResponse(null);
      setError(null);
      setParams(config);
    }
  };
};

export default useAxios;
