import image2 from 'images/banner_home2.png';
import { Carousel4, Carousel2, Carousel3 } from 'images';

export default [
  {
    imageUrl: Carousel4,
    text: 'Trabajando todo los dias para dar un mejor servicio'
  },

  {
    imageUrl:
      'https://www.citolab.com.pe/wp-content/uploads/2017/09/analisis-clinicos-banner.jpg'
  },

  {
    imageUrl: image2
  }
];
