import styled from 'styled-components';
import { Icon as I } from 'ts-react-feather-icons';

export const Footer = styled.footer`
  background-color: ${props => props.theme.colors.darkBlue};
  padding: 1rem 1.75em 0.5rem;
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 1rem 5em;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const QualityPolitics = styled.div`
  width: 50%;
  margin-right: 8%;
  @media ${props => props.theme.breakpoints.laptop} {
    width: 40%;
    margin-right: 8%;
  }
`;

export const ExtraInfo = styled.div`
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-start;
  flex-wrap: wrap;
  @media ${props => props.theme.breakpoints.laptop} {
    width: 50%;
  }

  @media ${props => props.theme.breakpoints.desktop} {
    width: 40%;
  }
`;

export const Wrapper = styled.div``;

export const TextBody1 = styled.p<{ bold: boolean }>`
  ${props =>
    props.bold ? props.theme.typo.body2.bold : props.theme.typo.body2.light};
  font-size: 0.25em;
  text-align: justify;
  font-weight: normal;
  line-height: 8px;
  color: ${props => props.theme.colors.white};
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 0.75em;
    line-height: 16px;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1rem;
    line-height: 20px;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.25rem;
    line-height: 25px;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 1.5em;
    line-height: 30px;
  }
`;

export const TextBody2 = styled.p<{ align: string }>`
  ${props => props.theme.typo.body2.light};
  font-size: 0.25em;
  text-align: ${props => props.align};
  font-weight: normal;
  line-height: 8px;
  color: ${props => props.theme.colors.white};
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 0.75em;
    line-height: 16px;
  }
  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 1em;
    line-height: 20px;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 1.25em;
    line-height: 25px;
  }
  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 1.5em;
    line-height: 30px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const Icon = styled(I).attrs(props => ({
  name: props.name,
  size: '1.75vw',
  color: props.theme.colors.white
}))`
  margin-right: 0.75em;
`;
