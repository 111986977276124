import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TitleSection from 'components/titleSection';
import { ListaAnalisis, Categoria1, Categoria3 } from 'images';
import MiniCard from 'components/miniCard';
import { WrapperLocations } from './styles';

import Ordenado from './A-Z';
import Categorias from './categorias';

/* Components */

const Cards = () => (
  <WrapperLocations>
    <MiniCard
      text="Orden Alfabetico"
      image={Categoria1}
      redirect={'/analisis/A-Z'}
    />
    <MiniCard text="Mostrar por Seccion " image={Categoria3} redirect={''} />
  </WrapperLocations>
);

export default () => {
  return (
    <>
      <TitleSection text="Lista de analisis" image={ListaAnalisis} />
      <Ordenado />
      {/* 
      <Switch>
        <Route exact path={`/analisis`} component={Cards} />
        <Route path={`/analisis/categorias`} component={Categorias} />
        <Route path={`/analisis/A-Z`} component={Ordenado} />
      </Switch> */}
    </>
  );
};
