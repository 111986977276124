export default {
  mobileS: '(max-width: 320px)',
  mobileM: '(max-width: 375px)',
  mobileL: '(max-width: 425px)',
  tablet: '(min-width: 768px)',
  laptop: '(min-width: 1024px)',
  laptopM: '(min-width: 128px)',
  laptopL: '(min-width: 1440px)',
  desktop: '(min-width: 1920px)'
};
