import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const Container = styled(NavLink)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  box-shadow: 2px 2px 5px #999;
  padding-bottom: 2%;

  @media ${props => props.theme.breakpoints.laptop} {
    width: 20%;
    padding-bottom: 1%;
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const Text = styled.p`
  ${props => props.theme.typo.body1.regular};
  text-align: center;
  font-weight: normal;
  font-size: 0.75em;
  line-height: 1.1em;
  max-width: 85%;
  margin: 0;
  word-wrap: break-word;
  color: black;
  @media ${props => props.theme.breakpoints.tablet} {
    font-size: 1.5em;
  }
  @media ${props => props.theme.breakpoints.laptopL} {
    font-size: 2em;
  }

  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 3em;
  }
`;

export const WrapperImage = styled.div`
  display: flex;
  justify-content: center;
  width: 65%;
  height: 55%;
  margin-bottom: 8%;
  margin-top: 10%;
`;

export const Image = styled.img`
  max-width: 85%;
`;
