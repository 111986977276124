import React, { useEffect, useState } from 'react';
import {
  Suggestion,
  TagName,
  Icon,
  Button,
  Input,
  Container,
  Content,
  Prueba
} from './styles';

interface Props {
  items: any[];
  placeholder: string;
  selectProp: string[];
  minLenght: number;
  onChange: Function;
}

const textWithValues = (item: any, selectProp: string[]) => {
  let text = '';
  selectProp.forEach(prop => {
    text += `${item[prop].trim()} `;
  });
  return text.trim();
};

const Autocomplete: React.FC<Props> = ({
  items,
  minLenght,
  onChange,
  selectProp,
  placeholder
}) => {
  const [text, setText] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const changeHandler = (value: any) => {
    let matches = [];
    if (value.length <= 0) {
      setText(value);
      return setSuggestions([]);
    }
    if (value.length >= minLenght) {
      matches = items.filter(item => {
        const regexStr = '(?=.*' + value.split(/\,|\s/).join(')(?=.*') + ')';
        const searchRegEx = new RegExp(regexStr, 'gi');
        if (item[selectProp[0]] == null) return false;
        return textWithValues(item, selectProp).match(searchRegEx);
      });
    }

    setSuggestions(matches);
    setText(value);
  };

  const onSelect = (value: any) => {
    onChange(value);
    setText('');
  };

  useEffect(() => {
    if (text == '') {
      return setSuggestions([]);
    }
  }, [text]);

  return (
    <Content>
      <Container>
        <Input
          placeholder={placeholder}
          value={text}
          onChange={e => changeHandler(e.target.value)}
        />
        <Button>
          <Icon name="search" color="white" />
        </Button>
      </Container>
      <Prueba>
        {suggestions &&
          suggestions.length > 0 &&
          suggestions.map((suggestion, index) => (
            <Suggestion
              key={index}
              onClick={ev => {
                onSelect(suggestion);
                ev.stopPropagation();
              }}
            >
              <TagName>{textWithValues(suggestion, selectProp)}</TagName>
              <Icon name="plus-square" color="darkBlue" />
            </Suggestion>
          ))}
      </Prueba>
    </Content>
  );
};

export default Autocomplete;
