import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ScreenLoader from 'components/screenLoader';
import {
  BackgroundTable,
  BodyTable,
  Button,
  Container,
  Content,
  HeadTable,
  Icon,
  Input,
  Table,
  WrapperLocations
} from '../styles';
import { Subtitle, Stack } from 'styles/elements';
import Paginator from 'components/paginator';
import EmptyCard from 'components/emptyCard';

/* Components */

const TablaAnalisis = ({
  data,
  currentPage,
  setCurrentPage
}: {
  data: any[];
  currentPage: number;
  setCurrentPage: Function;
}) => {
  const [items, setItems] = useState<any>([]);
  const [itemsPerPage] = useState<number>(10);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (data.length > itemsPerPage) {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const dataPage = data.slice(indexOfFirstItem, indexOfLastItem);

      setItems(dataPage);
    } else {
      setItems(data);
    }
  }, [data, currentPage]);

  return (
    <>
      {items.length > 0 ? (
        <BackgroundTable>
          <Table>
            <thead>
              <tr>
                <HeadTable porcentage={20} scope="col">
                  Nombre
                </HeadTable>
                <HeadTable porcentage={15} scope="col">
                  Muestra
                </HeadTable>
                <HeadTable porcentage={20} scope="col">
                  Metodo
                </HeadTable>
                <HeadTable porcentage={15} scope="col">
                  Tiempo de entrega
                </HeadTable>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((item: any, index: any) => (
                  <tr key={index}>
                    <BodyTable>{item.nombre.trim()}</BodyTable>
                    <BodyTable>{item.muestra.trim()}</BodyTable>
                    <BodyTable>{item.metodo.trim()}</BodyTable>
                    <BodyTable>
                      {item.tiempoEntrega == '' || item.tiempoEntrega == null
                        ? 'En el dia'
                        : item.tiempoEntrega.trim() + ' dias'}
                    </BodyTable>
                  </tr>
                ))}
            </tbody>
          </Table>
        </BackgroundTable>
      ) : (
        <EmptyCard
          title="No hay resultado"
          message="Lo sentimos no existe el analisis que estas buscando"
        />
      )}

      {data.length >= itemsPerPage && (
        <Paginator
          postPerPage={itemsPerPage}
          totalItems={data.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default () => {
  const [data, setData] = useState<any>();
  const [filtered, setFiltered] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [Loading, setLoading] = useState<boolean>(false);

  const onSearch = () => {
    const matches = data.filter((item: any) => {
      const regex = new RegExp(`${search}`, 'gi');
      return item.nombre.match(regex);
    });
    setCurrentPage(1);
    setFiltered(matches);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}analisis/alfabeticamente`)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  }, []);

  return (
    <>
      <WrapperLocations>
        <Stack>
          <Stack>
            <Subtitle>Encuentra el Analisis que necesitas</Subtitle>
            <Content>
              <Container>
                <Input
                  placeholder="Ingrese el nombre del analisis"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <Button onClick={onSearch} disabled={search.length < 1}>
                  <Icon name="search" color="white" />
                </Button>
              </Container>
            </Content>
          </Stack>
          <Stack>
            {(data || filtered) && (
              <TablaAnalisis
                data={filtered ? filtered : data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Stack>
        </Stack>
      </WrapperLocations>
      {Loading && <ScreenLoader />}
    </>
  );
};
